.App::-webkit-scrollbar {
    display: none;
}

.landing-page {
    font-family: Arial, sans-serif;
}

.landing-page::-webkit-scrollbar {
    display: none !important;
}

.about-section {
    width: 80%;
    gap: 5px;
}

.top-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
}

.header-nav a {
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
}

.signup-btn {
    margin-right: 93px;
    margin-left: 30px;
    padding: 8px 20px;
    border: none;
    border-radius: 10px;
    height: 50px;
    background-color: #FEBA0599;
    cursor: pointer;
}

.login-btn {
    background-color: #555;
}

.first-section {
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    height: 1000px;
    padding: 0px 20px 0px 50px;
    flex-direction: column;
    display: flex;
    position: relative;
}

.second-section {
    width: 100%;
    justify-content: space-around;
    gap: 20px;
}

.third-section {
    width: 100%;
    padding: 80px;
    gap: 20px;
}

.fourth-section {
    margin-top: 100px !important;
}

.card-section {
    width: 100%;
    gap: 20px;
}

.card {
    width: 100%;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.section4_card {
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.fifth-section {
    padding: 80px;
    margin-top: 100px !important;
    background: linear-gradient(to right, #5D4401, #FEB904);
}

.sixth-section {
    width: 100%;
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #F6F6F6;
}

.landing_footer {
    font-family: 'Outfit', sans-serif;
    padding: 60px 85px 30px 85px;
    width: 100%;
}

.btn-warning {
    background-color: #FEBA05 !important;
    border-color: #FEBA05 !important;
    -webkit-tap-highlight-color: transparent;
    transform: translateZ(0);
}

/* Font styles */
.font96_outfit {
    font-size: 96px;
    font-family: 'Outfit', sans-serif;
    line-height: 1;
    font-weight: 700;
}

.font80_outfit {
    font-size: 80px;
    font-family: 'Outfit', sans-serif;
    line-height: 80px;
    font-weight: 700;
}

.font36_outfit {
    font-size: 34px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: -2%;
}

.font64_outfit {
    font-size: 64px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
}

.font60_outfit {
    font-size: 60px;
    font-family: 'Outfit', sans-serif;
    font-weight: 700;
}

.font40_outfit {
    font-size: 40px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 700;
}

.font32_outfit {
    font-size: 32px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: -2%;
}

.font24_outfit {
    font-size: 20px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 400;
}

.font20_outfit {
    font-size: 20px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 400;
}

.font16_dmsans {
    font-size: 16px;
    font-family: 'DM Sans', sans-serif;
    line-height: 25px;
    font-weight: 400;
}

.font16_outfit {
    font-size: 16px;
    font-family: 'Outfit', sans-serif;
    line-height: 25px;
    font-weight: 400;
}

.font14_outfit {
    font-size: 14px;
    font-family: 'Outfit', sans-serif;
    line-height: 24px;
    font-weight: 500;
}

.text-dark {
    color: #000 !important;
}

.text-gray {
    color: #545454 !important;
}

.text-bold {
    font-weight: 700;
}

.register-container {
    display: flex;
    flex-direction: row;
}

.nav-container {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 20px;
}

.women-container {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;
    max-width: 50%;
    max-height: 80%;
    display: flex;
    justify-content: right;
}

.store-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

@media screen and (max-width: 680px) {
    .first-section {
        padding: 0px 20px 0px 10px;
    }

    .register-container {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .signup-btn {
        margin-right: 0px;
    }

    .nav-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        gap: 20px;
    }

    .fifth-section {
        padding: 20px;
    }

    .third-section {
        padding: 20px;
    }

    .landing_footer {
        gap: 20px;
        padding: 30px;
    }

    .third-section {
        padding: 30px;
    }

    .fourth-section {
        padding: 30px;
    }

    .card-section {
        width: 90%;
        gap: 20px;
    }

    .card {
        padding: 10px;
    }

    .section4_card {
        padding: 10px;
    }

    .third-section.font80_outfit {
        font-size: 30px;
    }

    .font96_outfit {
        font-size: 60px;
        font-family: 'Outfit', sans-serif;
        line-height: 1;
        font-weight: 700;
    }

    .women-container {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
    }

    .store-container {
        flex-direction: column;
    }
    
}